<template>
  <b-container class="p-3x" fluid>
    <b-overlay :show="load" opacity="0.5" no-wrap></b-overlay>
    <b-card body-class="py-4" class="bs-br">
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="6" lg="5" xl="4" class="me-xl-5">

              <b-form-group label="E-Mail Address *" label-for="email" class="mb-3">
                <b-form-input id="email" v-model="form.email" type="email" placeholder="user@user.com"></b-form-input>
              </b-form-group>
              <div class="d-flex justify-content-end mt-2">
                <b-button variant="secondary" class="px-3" @click="addEmail()">Add email</b-button>
              </div>
          </b-col>

          <b-col cols="12" md="6" lg="7" xl="4" class="mt-4 mt-md-0 ms-xl-5 border py-2"
            style="border-radius: 5px">
            <b-container fluid class="p-0">
              <b-row>
                <b-col cols="12">
                  <h5 class="fw-bold">Emails</h5>
                </b-col>
              </b-row>
              <b-row v-for="(email, index) in emails" :key="index">
                <b-col cols="auto" xl="auto">
                  <label>{{ email.email }}</label>
                </b-col>
                <b-col cols="auto">
                  <b-button variant="default" class="p-0" @click="removeEmail(index)">
                    <vue-fontawesome icon="times"/>
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="!emails.length > 0">
                <b-col cols="12">No emails found</b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-row class="mt-3">
      <b-form @submit.prevent="addNotification" class="w-100" style="max-width: none">
        <b-row align-h="end">
          <b-col cols="auto">
            <b-button type="submit" variant="primary" class="px-4">Save</b-button>
            <b-button type="reset" variant="secondary" class="px-4" @click="$router.push({name: 'forms.index'})">Cancel</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-row>
  </b-container>
</template>
<script>
import { required, email, minLength, maxLength} from 'vuelidate/lib/validators';
export default {
  name: 'notifications',

  data() {
    return {
      form: {
        email: null,
      },
      emails: [],
      load: false,
      errorRole: false
    };
  },
  validations: {
    form: {
      email: { required, email, maxLength: maxLength(60) },
    },
  },

  mounted() {
    this.getNotifications();
  },

    methods: {
      addEmail(){
        if(this.form.email != null) {
          this.emails.push({ email: this.form.email });
          this.form.email = null;
        }
      },
      removeEmail(index) {
        this.emails.splice(index, 1);
      },
      getNotifications(){
        this.load = true;
        this.$http.get(`marketing/forms/${this.$route.params.form_id}/notification`, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
          if (response.status === 200) {
            this.emails = response.body.data;
          }
          this.load = false;
        }, (error) => {
          if (error.status == 500) {
            this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
          }
        })
      },
      addNotification(){
          this.load = true;
          const formData = new FormData();

          for (let i = 0; i < this.emails.length; i++) {
            for (let key of Object.keys(this.emails[i])) {
              formData.append(`emails[${i}][${key}]`, this.emails[i][key]);
            }
          }

          this.$http.post(`marketing/forms/${this.$route.params.form_id}/notification`, formData, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
            if (response.status === 200) {
              this.$notify({ group: 'app', text: response.body.message, type: 'success' });
              this.$router.push({ name: 'forms.index' });
            }
            this.load = false;
          }, (error) => {
            if (error.status == 500) {
              this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
            }
            this.load = false;
          });
      },
    },
};
</script>
